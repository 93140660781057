import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Constant } from 'src/app/commons/Constants';
import { MessageEn } from 'src/app/commons/MessageEn';
import { MessageJp } from 'src/app/commons/MessageJp';
import { Utils } from 'src/app/commons/Utils';
import { AppService } from 'src/app/service/app.service';
import { InfoService } from 'src/app/service/info.service';
import { ShippingService } from 'src/app/service/shipping.service';
import { SpinnerService } from 'src/app/service/spinner.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit {
  constructor(
    private router: Router, 
    private appService: AppService, 
    private shippingService: ShippingService,
    private infoService: InfoService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private spinner: SpinnerService
  ) {}

  dialogFlag: boolean = false;

  formUser: FormGroup = new FormGroup({});

  mes = MessageJp;

  submitFlag: boolean = false;

  alertsRes: any[] = [];

  ngOnInit(): void {
    this.appService.clearAllData();
    this.mes = MessageJp;
    this.handleLangChange();
    this.initForm();
    this.getAlert();
  }

  handleLangChange() {
    this.appService.selectedLangListener.subscribe({
      next: (res) => {
        this.appService.selectedLang == 0
          ? (this.mes = MessageJp)
          : (this.mes = MessageEn);

        this.InfoNotifiMultiLang = this.setInfoNotifiMultiLang();
      },
    });
  }

  initForm() {
    this.formUser = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      shipId: new FormControl(null, [Validators.required])
    })
  }
  
  openDialog() {
    this.dialogFlag = true;
  }

  closeDialog() {
    this.dialogFlag = false;
  }

  moveToRegist() {
    this.router.navigate(['carry-reserve/regist']).then(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  moveToConfirm() {
    this.submitFlag = true;
    if(this.formUser.invalid) {
      return;
    }
    this.spinner.requestStarted();
    const shipId = parseInt(Utils.getStringWithPadingZero(this.formUser.value?.shipId));

    this.shippingService.checkShipRequestExist(shipId, this.formUser.value?.email)
    .subscribe({
      next: (res: any) => {
        if(res.status == Constant.HTTP_200 && res.data > 0) {
          this.getShippingRequest(shipId);
        }
        else {
          this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.MSG_068 });
          this.spinner.resetSpinner();
        }
      },
      error: (err) => this.spinner.resetSpinner()
    })
  }

  getShippingRequest(shipId: number) {
    this.shippingService.getShippingRequest(shipId)
    .subscribe({
      next: (res) => {
        if(res.status == Constant.HTTP_200) {
          this.appService.saveRequest(res.data);
          this.router.navigate(['carry-reserve/confirm'], {queryParams: {param: Constant.UPDATE}}).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
        }
        else {
          this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR });
        }
      },
      error: (err) => this.messageService.add({ severity: 'error', summary: this.mes.ERR_TITLE, detail: this.mes.SERVER_ERRR }),
      complete: () => {
        this.spinner.resetSpinner();
      }
    })
  }

  getAlert() {
    this.infoService.getAlerts().subscribe({
      next: (res) => {
        if(res.status == Constant.HTTP_200) {
          this.alertsRes = res.data;
          this.InfoNotifiMultiLang = this.setInfoNotifiMultiLang();
        }
      },
      error: (err) => console.log(err)
    })
  }

  InfoNotifiMultiLang: string = ''
  setInfoNotifiMultiLang() {
    const contentInfo = this.alertsRes.map(x => x.infoContents);
    const contentInfoEn = this.alertsRes.map(x => x.infoContentsEn);
    const contentInfoKo = this.alertsRes.map(x => x.infoContentsKo);
    const contentInfoZhcn = this.alertsRes.map(x => x.infoContentsZhcn);
    const contentInfoZhtw = this.alertsRes.map(x => x.infoContentsZhtw);

    switch(this.appService.selectedLang) {
      case 0: return contentInfo.join("<br><br>");
      case 1: return contentInfoEn.join("<br><br>");
      case 2: return contentInfoKo.join("<br><br>");
      case 3: return contentInfoZhcn.join("<br><br>");
      case 4: return contentInfoZhtw.join("<br><br>");
      default: return contentInfo.join("<br><br>");
    }
  }
}
