<div class="container">
  <p>{{ "regist.cstInfo" | translate }}</p>
  <form [formGroup]="formData">
    <div class="input-area">
      <div class="form-info">
        <div class="item-info">
          <p>{{ "regist.hotelName" | translate }}</p>
          <!-- <input type="text" pInputText class="p-inputtext-sm"/> -->
          <!-- <p-dropdown
            [options]="pdFacility"
            optionLabel="name"
            optionValue="value"
            [filter]="true"
            filterBy="name,name1,name2"
            formControlName="stayFacilityId"
            placeholder=" "
          ></p-dropdown> -->
          <p-autoComplete
            [dropdown]="true"
            formControlName="stayFacilityId"
            [suggestions]="pdFacility"
            (completeMethod)="filterStayFacility($event)"
            field="name"
          ></p-autoComplete>
          <div
            *ngIf="
              formData.controls['stayFacilityId']?.invalid &&
              formData.controls['stayFacilityId']?.dirty
            "
            class="alert p-error"
          >
            <div
              *ngIf="formData.controls['stayFacilityId'].errors?.['required']"
            >
              {{ "regist.hotelName" | translate }}{{ mes.MSG_056 }}
            </div>
          </div>
        </div>
        <div class="item-info">
          <p>{{ "regist.hotelReservationName" | translate }}</p>
          <input
            formControlName="reserverName"
            type="text"
            pInputText
            maxlength="100"
            class="p-inputtext-sm"
          />
          <div
            *ngIf="
              formData.controls['reserverName'].invalid &&
              formData.controls['reserverName'].dirty
            "
            class="alert p-error"
          >
            <div *ngIf="formData.controls['reserverName'].errors?.['required']">
              {{ "regist.hotelReservationName" | translate }}{{ mes.MSG_056 }}
            </div>
            <div
              *ngIf="formData.controls['reserverName'].errors?.['halfwidth']"
            >
              {{ "regist.hotelReservationName" | translate }}{{ mes.MSG_016 }}
            </div>
          </div>
        </div>
        <div class="item-info">
          <p>{{ "regist.dateOfUse" | translate }}</p>
          <p-calendar
            formControlName="usageDate"
            styleClass="calendarcustom"
            dateFormat="yy/mm/dd"
            [showIcon]="true"
            (onSelect)="getListBaggage()"
            (onInput)="getListBaggage()"
            [minDate]="minSelectableDate"
            [maxDate]="maxSelectableDate"
            [disabledDates]="disabledDates"
          ></p-calendar>
          <div
            *ngIf="
              formData.controls['usageDate'].invalid &&
              formData.controls['usageDate'].dirty
            "
            class="alert p-error"
          >
            <div *ngIf="formData.controls['usageDate'].errors?.['required']">
              {{ "regist.dateOfUse" | translate }}{{ mes.MSG_056 }}
            </div>
          </div>
        </div>
        <div class="item-info">
          <p>{{ "regist.tel" | translate }}</p>
          <input
            formControlName="tel"
            pInputText
            type="tel"
            numberOnly
            maxlength="15"
            class="p-inputtext-sm"
          />
          <div
            *ngIf="
              formData.controls['tel'].invalid && formData.controls['tel'].dirty
            "
            class="alert p-error"
          >
            <div
              *ngIf="formData.controls['tel'].errors?.['required'] || formData.controls['tel'].errors?.['min']"
            >
              {{ ("regist.tel" | translate).split(regex)[0] }}{{ mes.MSG_056 }}
            </div>
          </div>
        </div>
        <div class="item-info">
          <p>{{ "regist.email" | translate }}</p>
          <input
            formControlName="email"
            type="email"
            pInputText
            maxlength="126"
            class="p-inputtext-sm"
          />
          <div
            *ngIf="
              formData.controls['email'].invalid &&
              formData.controls['email'].dirty
            "
            class="alert p-error"
          >
            <div *ngIf="formData.controls['email'].errors?.['required']">
              {{ "regist.email" | translate }}{{ mes.MSG_056 }}
            </div>
            <div
              *ngIf="formData.controls['email'].errors?.['email'] && submitFlag"
            >
              {{ "regist.email" | translate }}{{ mes.MSG_053 }}
            </div>
          </div>
        </div>
      </div>

      <section class="baggage-info">
        <p class="baggage">{{ "regist.baggage" | translate }}</p>
        <div *ngIf="baggageTotalPrice <= 0 && submitFlag" class="p-error">
          {{ mes.MSG_080_A01 }}
        </div>
        <div *ngFor="let item of listBaggageWithLang">
          <div class="baggage-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
            >
              <path
                d="M7.41235 19.7597L32.1624 30.7598L33.8377 26.9903L9.08765 15.9903L7.41235 19.7597ZM35.0625 59.125V28.875H30.9375V59.125H35.0625ZM33.8377 30.7598L58.5877 19.7597L56.9124 15.9903L32.1624 26.9903L33.8377 30.7598Z"
                fill="#490E00"
              />
              <path
                d="M8.25 47.0528V18.9473C8.25 18.2952 8.63401 17.7043 9.22988 17.4395L32.3298 7.17283C32.7566 6.98321 33.2434 6.98321 33.6702 7.17283L56.7702 17.4395C57.3661 17.7043 57.75 18.2952 57.75 18.9473V47.0528C57.75 47.7048 57.3661 48.2958 56.7702 48.5606L33.6702 58.8272C33.2434 59.0167 32.7566 59.0167 32.3298 58.8272L9.22988 48.5606C8.63401 48.2958 8.25 47.7048 8.25 47.0528Z"
                stroke="#490E00"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.625 12.375L44.3952 22.9395C44.9911 23.2043 45.375 23.7952 45.375 24.4473V34.375"
                stroke="#490E00"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="baggage-option">
              <p class="baggage-name">{{ item.name }}</p>
              <p class="baggage-detail">{{ item.explain }}</p>
              <p class="price">
                {{ item.shippingFeeNormal | currency : "JPY" : "" }}円
              </p>
            </div>
            <p-inputNumber
              [format]="false"
              [min]="0"
              [max]="99"
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              [formControlName]="'baggage' + item.id"
            ></p-inputNumber>
          </div>
        </div>
      </section>
    </div>
    <div class="regist-footer-pc">
      <div class="space"></div>
      <div class="price-pc">
        <p *ngIf="discountAvail" class="baggage-note">{{ "regist.note" | translate }}</p>
        <div class="total-price">
          <p class="lbl">{{ "regist.shippingFeeTotal" | translate }}</p>
          <p class="price">{{ baggageTotalPrice | currency : "JPY" : "" }}円</p>
        </div>
      </div>
    </div>
    <div class="end">
      <button
        (click)="data?.id ? moveToConfirm() : checkTime1130()"
        pButton
        class="p-button-warning btn-confirm btn-custom"
      >
        {{ "regist.btnConfirm" | translate }}
      </button>
    </div>
  </form>
</div>

<!-- popup -->
<p-dialog [showHeader]="false" [modal]="true" [contentStyle]="{backgroundColor: '#EBE6C8', padding: '0'}" position="center" [(visible)]="dialogFlag" [style]="{width: '320px'}">
  <div class="close">
      <i class="pi pi-times" (click)="closeDialog()" style="font-size: 15px; padding: 8px;"></i>
  </div>
  <div class="alert-popup">
    <p>{{'regist.alert' | translate}}</p>
  </div>
  <div class="bottom">
    <button (click)="closeDialog()" pButton class="p-button-warning btn-app">{{'regist.btnClose' | translate}}</button>
  </div>
</p-dialog>
