import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BaseWebModule } from './base-web.module';
import { ErrorInterceptor } from './security/errors-interceptor';
import { MasterFrameComponent } from './screens/master-frame/master-frame.component';
import { RegistComponent } from './screens/regist/regist.component';
import { DropdownModule } from 'primeng/dropdown';
import { TopComponent } from './screens/top/top.component';
import { ConfirmComponent } from './screens/confirm/confirm.component';
import { InputTextModule } from 'primeng/inputtext';
import { LanguageDropdownComponent } from './customs/language-dropdown/language-dropdown.component';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { NumberOnlyDirective } from './directives/number.directive';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PreviewComponent } from './screens/preview/preview.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ThanksComponent } from './screens/thanks/thanks.component';
import { PolicyComponent } from './screens/policy/policy.component';
import { TransactionLawComponent } from './screens/transaction-law/transaction-law.component';
import { AutoCompleteModule } from 'primeng/autocomplete';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    MasterFrameComponent,
    RegistComponent,
    TopComponent,
    ConfirmComponent,
    PreviewComponent,
    ThanksComponent,
    PolicyComponent,
    TransactionLawComponent,
    NumberOnlyDirective,
    LanguageDropdownComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PanelMenuModule,
    DropdownModule ,
    ProgressSpinnerModule,
    InputTextModule,
    CalendarModule,
    InputNumberModule,
    AutoCompleteModule,
    ToastModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ja',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BaseWebModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    TranslateService,
    ConfirmationService,
    MessageService,
    DialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
